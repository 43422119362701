import { useEffect, useRef } from 'react';
import { IntrinsicProps, createComponent } from '@/common/util/templateHelpers';
import { Flex, IconFA } from '@/common/components';

import { useBreakpoint } from '@/hooks/useBreakpoint';

import style from './index.module.scss';
import { Button } from '@/common/components/controls';
import { faArrowLeft, faArrowRight, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const SHAKE_ANIM_DURATION = 300;

interface CarouselPos {
  x: number
  index: number
}

interface FluidCarouselProps extends IntrinsicProps {
  onNext?: () => Promise<boolean>
  loading?: boolean
}

export default createComponent<FluidCarouselProps>('FluidCarousel', { style }, function FluidCarousel ({ className }, props) {
  const wrapperElRef = useRef<HTMLDivElement>(null);
  const innerElRef = useRef<HTMLDivElement>(null);
  const controlsElRef = useRef<HTMLDivElement>(null);
  const currentXRef = useRef(0);
  const currentIndexRef = useRef(0);
  const lastPosStackRef = useRef<CarouselPos[]>([]);
  const shakeTimeoutRef = useRef<NodeJS.Timeout>(null);
  const isTouchingRef = useRef(false);

  const currentBreakpoint = useBreakpoint({
    onResize: async (breakpoint, lastBreakpoint) => {
      if (breakpoint === lastBreakpoint) return;

      const innerEl = innerElRef.current;
      if (!innerEl) return;

      lastPosStackRef.current = [];
      currentXRef.current = 0;
      currentIndexRef.current = 0;
  
      innerEl.style.transform = 'translateX(0px)';
    }
  });

  const playReachedEndAnim = (direction: 'next' | 'prev') => {
    const wrapperEl = wrapperElRef.current;
    if (!wrapperEl) return;

    wrapperEl.classList.add(`--shake__${direction}`);

    if (shakeTimeoutRef.current) clearTimeout(shakeTimeoutRef.current);
    setTimeout(() => {
      wrapperEl.classList.remove('--shake__next', '--shake__prev');
    }, SHAKE_ANIM_DURATION);
  };
  
  const next = async () => {
    const innerEl = innerElRef.current;
    if (!innerEl) return;
    
    const wrapperEl = wrapperElRef.current;
    if (!wrapperEl) return;
    if (wrapperEl.classList.contains('--shake__next')) return;
    if (wrapperEl.classList.contains('--shake__prev')) return;

    if (props.onNext) {
      const shouldContinue = await props.onNext();
      if (!shouldContinue) return;
    }

    const slides = Array.from(innerEl.querySelectorAll('.FluidCarousel__Slide')) as HTMLElement[];
    if (!slides.length) return playReachedEndAnim('next');
    if (currentIndexRef.current >= slides.length) return playReachedEndAnim('next');

    const lastX = currentXRef.current;
    const lastIndex = currentIndexRef.current;

    let found: HTMLElement = null;
    while (!found) {
      const slide = slides[ currentIndexRef.current ];
      if (!slide) break;

      const rect = slide.getBoundingClientRect();

      if (rect.right > innerEl.parentElement.clientWidth) {
        found = slide;
      } else {
        currentIndexRef.current++;
      }
    }

    if (!found) return playReachedEndAnim('next');

    lastPosStackRef.current.push({
      x: lastX,
      index: lastIndex
    });

    currentXRef.current = found.offsetLeft;
    
    innerEl.style.transform = `translate3d(-${found.offsetLeft}px, 0px, 0px)`;

    wrapperElRef.current.classList.remove('--firstSlide');
    controlsElRef.current.classList.remove('--firstSlide');
  };

  const prev = () => {
    const innerEl = innerElRef.current;
    if (!innerEl) return;
    
    const wrapperEl = wrapperElRef.current;
    if (!wrapperEl) return;
    if (wrapperEl.classList.contains('--shake__next')) return;
    if (wrapperEl.classList.contains('--shake__prev')) return;

    if (!lastPosStackRef.current.length) return playReachedEndAnim('prev');
    const { x, index } = lastPosStackRef.current.pop();

    currentXRef.current = x;
    currentIndexRef.current = index;

    innerEl.style.transform = `translateX(-${x}px)`;

    if (!lastPosStackRef.current.length) {
      wrapperElRef.current.classList.add('--firstSlide');
      controlsElRef.current.classList.add('--firstSlide');
    }
  };

  useEffect(() => {
    if (currentBreakpoint !== 'mobile') return;

    const onTouchStart = () => {
      props.onNext();
    };
  
    const wrapperEl = wrapperElRef.current;
    if (!wrapperEl) return;

    wrapperEl.addEventListener('touchstart', onTouchStart);

    return () => {
      wrapperEl.removeEventListener('touchstart', onTouchStart);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={className}>
      <div className='FluidCarousel__Wrapper --firstSlide' ref={wrapperElRef}>
        <div className='FluidCarousel__Inner' ref={innerElRef}>
          {props.children}
        </div>
      </div>
      <div ref={controlsElRef} className='FluidCarousel__Controls --firstSlide'>
        <Button primary onClick={() => prev()} ariaLabel='Show Previous'>
          <IconFA icon={faChevronLeft} />
        </Button>
        <Button primary onClick={() => next()} loading={props.loading} ariaLabel='Show Next'>
          <IconFA icon={faChevronRight} />
        </Button>
      </div>
    </div>
  );
});



/* #region Slide Components */

export const SlideSingle = createComponent('FluidCarousel__Slide', { style }, function SlideSingle ({ className }, props) {
  return (
    <div className={className + ' --single'}>
      {props.children}
    </div>
  );
});

export const SlideTwoRows = createComponent('FluidCarousel__Slide', { style }, function SlideTwoRows ({ className, slots }) {
  return (
    <Flex directionColumn className={className + ' --twoRows'}>
      <Flex fit>{slots.topRow}</Flex>
      <Flex fit>{slots.bottomRow}</Flex>
    </Flex>
  );
});

export const SlideFeature = createComponent('FluidCarousel__Slide', { style }, function SlideFeature ({ className, slots }) {
  return (
    <Flex directionColumn className={className + ' --feature'}>
      <Flex fit>
        {slots.feature}
      </Flex>
      <Flex fit>
        <Flex fit>{slots.leftCol}</Flex>
        <Flex fit>{slots.rightCol}</Flex>
      </Flex>
    </Flex>
  );
});

/* #endregion */
