import { Slot, If, createComponent, IntrinsicProps, toClassName } from '@/common/util/templateHelpers';
import AgGameTile from '@/components/AgGameTile';
import FluidCarousel, { SlideTwoRows, SlideFeature, SlideSingle } from '@/components/FluidCarousel';
import { GameBasic } from '@/util/formatters';

import style from './index.module.scss';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import React, { useState } from 'react';
import { Flex, Loader } from '@/common/components';

interface FluidGameCarouselProps extends IntrinsicProps {
  onNext?: () => Promise<boolean>
  loading?: boolean
  ready?: boolean
  height?: 'featured' | 'default' | 'single'
}

export default createComponent<FluidGameCarouselProps>('FluidGameCarousel', { style }, function FluidGameCarousel ({ mergeClassNames, slots }, props) {
  const [ ready, setReady ] = useState(props.ready);

  const breakpoint = useBreakpoint({
    onReady: () => {
      setReady(true);
    }
  });

  const className = mergeClassNames(toClassName('FluidGameCarousel', { 
    [ `height-${props.height}` ]: !!props.height,
  }));
  
  return (
    <FluidCarousel 
      className={className} 
      onNext={props.onNext} 
      loading={props.loading}
    >
      {
        If(ready && props.ready !== false, () => (
          <>
            {
              If(breakpoint === 'mobile' && slots.mobile, () => (
                <>{slots.mobile}</>
              )).Else(() => (
                <>{slots.desktop}</>
              )).EndIf()
            }
          </>
        )).Else(() => (
          <SlideSingle>
            <Flex wide tall alignCenter justifyCenter style={{ margin: 'auto' }} className='--padMd0'>
              <div><Loader /></div>
            </Flex>
          </SlideSingle>
        )).EndIf()
      }
    </FluidCarousel>
  );
});



/* #region Slide Layouts */

interface GameSlidesProps extends IntrinsicProps {
  games: GameBasic[]
}

export function MobileGameSlides (props: GameSlidesProps) {
  const gamePairs: [ GameBasic, GameBasic ][] = [];

  for (let i = 0; i < props.games.length; i += 2) {
    const game = props.games[ i ];
    const nextGame = props.games[ i + 1 ];

    gamePairs.push([ game, nextGame ]);
  }

  return (
    <>
      {
        gamePairs.map((games, i) => (
          <SlideTwoRows 
            key={'mobile_slide_two_rows_' + i + '_' + games[ 0 ].nid} 
            className='--mobile'
          >
            <Slot name='topRow'>
              <AgGameTile 
                game={games[ 0 ]} 
                key={'mobile_slide_two_rows_game_0_' + i + '_' + games[ 0 ].nid}
              />
            </Slot>
            <Slot name='bottomRow'>
              { 
                If(games[ 1 ], () => (
                  <AgGameTile 
                    game={games[ 1 ]} 
                    key={'mobile_slide_two_rows_game_1_' + i + '_' + games[ 1 ].nid}
                  />
                ))
                  .Else(() => (<></>)).EndIf() }
            </Slot>
          </SlideTwoRows>
        ))
      }
    </>
  );
}

export function FeaturedGameSlides (props: GameSlidesProps) {
  const gamePairs: [ GameBasic, GameBasic, GameBasic ][] = [];

  for (let i = 0; i < props.games.length; i += 3) {
    const game = props.games[ i ];
    const nextGame = props.games[ i + 1 ];
    const nextNextGame = props.games[ i + 2 ];

    gamePairs.push([ game, nextGame, nextNextGame ]);
  }

  return (
    <>
      {
        gamePairs.map((games, i) => (
          <SlideFeature 
            key={'featured_slide_' + i + '_' + games[ 0 ].nid}
          >
            <Slot name='feature'>
              <AgGameTile 
                game={games[ 0 ]} 
                showLabel
                key={'featured_slide_game_0_' + i + '_' + games[ 0 ].nid}
              />
            </Slot>
            <Slot name='leftCol'>
              { 
                If(games[ 1 ], () => (
                  <AgGameTile 
                    game={games[ 1 ]} 
                    showLabel 
                    key={'featured_slide_game_1_' + i + '_' + games[ 1 ].nid}
                  />
                ))
                  .Else(() => (<></>)).EndIf() }
            </Slot>
            <Slot name='rightCol'>
              { 
                If(games[ 2 ], () => (
                  <AgGameTile 
                    game={games[ 2 ]} 
                    showLabel
                    key={'featured_slide_game_2_' + i + '_' + games[ 2 ].nid}
                  />
                ))
                  .Else(() => (<></>)).EndIf() }
            </Slot>
          </SlideFeature>
        ))
      }
    </>
  );
}

export const DesktopGameSlides = createComponent<GameSlidesProps>('DesktopGameSlides', {}, function ({ slots }, props) {
  const gamePairs: GameBasic[][] = [];

  for (let i = 0; i < props.games.length; i++) {
    const pair: GameBasic[] = [];
    pair.push(props.games[ i ]);

    if ( i % 5 !== 0) {
      pair.push(props.games[ i + 1 ]);
      i++;
    }

    gamePairs.push(pair);
  }

  if (slots?.ad) {
    gamePairs.splice(1, 0, [ { path: 'slots__ad' } as GameBasic ]);
  }

  return (
    <>
      {
        gamePairs.map((games, i) => (
          <React.Fragment 
            key={'desktop_slide_' + i + '_' + games[ 0 ].nid}
          >
            {
              If(games[ 0 ].path === 'slots__ad', () => (
                <SlideSingle className='--desktop'>
                  {slots.ad}
                </SlideSingle>
              ))
                .ElseIf(games[ 1 ], () => (
                  <SlideTwoRows className='--desktop'>
                    <Slot name='topRow'>
                      <AgGameTile 
                        game={games[ 0 ]}
                        key={'desktop_slide_game_0_' + i + '_' + games[ 0 ].nid}
                      />
                    </Slot>
                    <Slot name='bottomRow'>
                      { 
                        If(games[ 1 ], () => (
                          <AgGameTile 
                            game={games[ 1 ]}
                            key={'desktop_slide_game_1_' + i + '_' + games[ 1 ].nid}
                          />
                        ))
                          .Else(() => (<></>)).EndIf() }
                    </Slot>
                  </SlideTwoRows>
                )).Else(() => (
                  <SlideSingle className='--desktop'>
                    <AgGameTile 
                      game={games[ 0 ]}
                      key={'desktop_slide_game_2_' + i + '_' + games[ 0 ].nid}
                    />
                  </SlideSingle>
                )).EndIf()
            }
          </React.Fragment>
        ))
      }
    </>
  );
});

/* #endregion */
