import { logErrorEvent } from '@/common/util/logEvent';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { GameBasic } from '@/util/formatters';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { useInViewport } from 'react-in-viewport';

interface UseGameCarouselOptions {
  initialGames?: {
    mobile: GameBasic[]
    desktop: GameBasic[]
  }
  fetchGames: {
    mobile: (page: number) => Promise<GameBasic[]>
    desktop: (page: number) => Promise<GameBasic[]>
  }
  refreshOnRouteChange?: boolean
  lazyLoad?: boolean
  loop?: boolean
}

export function useGameCarousel (options: UseGameCarouselOptions) {
  const pageRef = useRef(0);
  const hasReachedEndRef = useRef(false);
  const [ loading, setLoading ] = useState(false);
  const [ ready, setReady ] = useState(false);
  const [ games, setGames ] = useState<GameBasic[]>([]);
  const router = useRouter();
  const carouselRef = useRef(null);
  const { inViewport } = useInViewport(carouselRef);
  const firstRenderRef = useRef(true);

  const breakpoint = useBreakpoint({
    onReady: (breakpoint) => {
      if (options.initialGames) {
        const initialGames = breakpoint === 'mobile' 
          ? options.initialGames.mobile 
          : options.initialGames.desktop;

        setGames(() => initialGames);
      }
    },

    onResize: async (breakpoint, lastBreakpoint) => {
      if (firstRenderRef.current && options.lazyLoad && !inViewport) return;

      if (breakpoint === lastBreakpoint) return;
      if (options.lazyLoad && !inViewport) return;

      const fetchGamesFn = breakpoint === 'mobile' 
        ? options.fetchGames.mobile 
        : options.fetchGames.desktop;
      
      try {
        pageRef.current = 0;
        const nextGames = await fetchGamesFn(0);
        setGames(() => nextGames);
        setReady(true);
      } catch (e) {
        logErrorEvent(e.message, true, e);
      }
    }
  });

  const fetchNextGames = async () => {
    if (hasReachedEndRef.current) return true;

    if (loading) return false;
    setLoading(true);

    const fetchGamesFn = breakpoint === 'mobile' 
      ? options.fetchGames.mobile 
      : options.fetchGames.desktop;
      
    try {
      pageRef.current++;
      const nextGames = await fetchGamesFn(pageRef.current);
      if (nextGames.length) {
        setGames((currentGames) => [ ...currentGames, ...nextGames ]);
      } else {
        if (options.loop) {
          pageRef.current = -1;
          fetchNextGames();
        } else {
          hasReachedEndRef.current = true;
        }
      }
    } catch (e) {
      logErrorEvent(e.message, true, e);
    }

    setLoading(false);
    return true;
  };

  useEffect(() => {
    if (!options.refreshOnRouteChange) return;

    const onRouteStart = () => {
      setReady(false);
    };

    const handleRouteChange = async () => {
      const fetchGamesFn = breakpoint === 'mobile' 
        ? options.fetchGames.mobile 
        : options.fetchGames.desktop;
      
      try {
        pageRef.current = 0;
        const nextGames = await fetchGamesFn(0);
        setGames(() => nextGames);
      } catch (e) {
        logErrorEvent(e.message, true, e);
      }

      setReady(true);
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('routeChangeStart', onRouteStart);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('routeChangeStart', onRouteStart);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ router.asPath, options.fetchGames ]);

  useEffect(() => {
    if (!options.lazyLoad) return;
    if (ready) return;
    if (options.lazyLoad && !inViewport) return;
    if (!firstRenderRef.current) return;

    (async () => {
      const fetchGamesFn = breakpoint === 'mobile' 
        ? options.fetchGames.mobile 
        : options.fetchGames.desktop;
      
      try {
        pageRef.current = 0;
        firstRenderRef.current = false;
        const nextGames = await fetchGamesFn(0);
        setGames(() => nextGames);
        setReady(true);
      } catch (e) {
        logErrorEvent(e.message, true, e);
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ inViewport ]);

  return {
    games,
    fetchNextGames,
    loading,
    ready,
    elRef: carouselRef,
  };
}
