import { useEffect, useRef, useState } from 'react';

type Breakpoint = 'mobile' | 'small' | 'full'

interface UseBreakpointOptions {
  onReady?: (breakpoint: Breakpoint) => void
  onResize?: (breakpoint: Breakpoint, lastBreakpoint: Breakpoint | null) => void
}

export function useBreakpoint (options: UseBreakpointOptions = {}) {
  const lastBreakpointRef = useRef<Breakpoint | null>(null);
  const lastWidthRef = useRef(0);
  const [ breakpoint, setBreakpoint ] = useState<Breakpoint>('mobile');
  const debounceRef = useRef<NodeJS.Timeout | null>(null);
  const readyRef = useRef(false);

  useEffect(() => {
    const handleResize = () => {
      window.clearTimeout(debounceRef.current);
      debounceRef.current = setTimeout(() => {
        let breakpoint: Breakpoint;

        const width = window.innerWidth;
        if (width === lastWidthRef.current) {
          lastWidthRef.current = width;
          return;
        }

        if (width < 1024) {
          breakpoint = 'mobile';
        } else if (width < 1366) {
          breakpoint = 'small';
        } else {
          breakpoint = 'full';
        }
        setBreakpoint(breakpoint);

        if (!readyRef.current) {
          readyRef.current = true;
          options?.onReady?.(breakpoint);
        }
        
        options?.onResize?.(breakpoint, lastBreakpointRef.current);

        lastBreakpointRef.current = breakpoint;
        lastWidthRef.current = width;
      }, 10);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ options ]);

  return breakpoint;
}
